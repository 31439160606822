const StickyTabs = {
  init: () => {
    const updateStickyTabs = () => {
      const mainNav = document.querySelector('.main-nav');
      if (!mainNav) return;

      const mainNavHeight = mainNav.offsetHeight;
      const browserWidth = document.documentElement.clientWidth;

      document.querySelectorAll('.sticky-tabs').forEach((tab) => {
        tab.style.top = `${mainNavHeight}px`;
        tab.style.width = `${browserWidth}px`;

        tab.style.marginLeft = '0px';
        const offsetLeft = tab.getBoundingClientRect().left;
        tab.style.marginLeft = `${-offsetLeft}px`;
        console.log(`Offset from left for .sticky-tabs: ${offsetLeft}px`);
      });
    };

    const handleScrollingTabsClick = () => {
      const mainNav = document.querySelector('.main-nav');
      if (!mainNav) return;

      const mainNavHeight = mainNav.offsetHeight;
      document.querySelectorAll('#scrolling-tabs > *').forEach((child) => {
        child.addEventListener('click', () => {
          const stickyTab = document.querySelector('.sticky-tabs');
          if (stickyTab) {
            const targetPosition = stickyTab.parentElement.offsetTop - mainNavHeight;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
          }
        });
      });
    };

    updateStickyTabs();
    handleScrollingTabsClick();

    window.addEventListener('resize', updateStickyTabs);
  },
};

export default StickyTabs;
