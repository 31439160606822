const PopoutModal = {
  init: () => {
    const alertBanner = document.getElementById('site-alert');
    const popout = document.getElementById('popout-modal');

    const containsMessage = (element) => {
      return element.innerText;
    };

    const messages = popout?.querySelectorAll('.message');
    const firstVisibleMessage = messages && Array.from(messages)?.find(containsMessage);

    function allowForAlertBanner() {
      if (alertBanner && !alertBanner.classList.contains('hidden')) {
        popout.style.top = `${(alertBanner.clientHeight + window.innerHeight * 0.4).toString()}px`;
      } else {
        popout.style.top = '40%';
      }
    }

    if (popout) {
      allowForAlertBanner();
      let top = window.pageYOffset | document.body.scrollTop;
      let popoutOffset = `-${(popout.clientHeight + 32).toString()}px`;
      popout.style.right = popoutOffset;

      if (popout.classList.contains('invisible')) {
        popout.classList.remove('invisible');
      }

      function newOffsetTop() {
        top = window.pageYOffset | document.body.scrollTop;
      }

      window.onscroll = () => {
        newOffsetTop();
      };

      window.onresize = () => {
        top = window.pageYOffset | document.body.scrollTop;
        popoutOffset = `-${(popout.clientHeight + 32).toString()}px`;
        popout.style.right = popoutOffset;
      };

      const openBtnsDesktop = document.querySelectorAll('#open-popout--desktop');
      const openBtnsMobile = document.querySelectorAll('#open-popout--mobile');

      const formHolder = document.querySelector('.popout-form-holder');

      openBtnsDesktop.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          document.body.classList.toggle('overflow-x-hidden');
          if (popout.classList.contains('popout-closed')) {
            popout.classList.remove('popout-closed');
            popout.classList.add('popout-open');
            popout.classList.remove('rotate-90');
            popout.classList.remove('md:rotate-270');
            popout.classList.remove('lg:rotate-270');
            popout.style.right = '0';
            btn.firstElementChild.classList.remove('hidden');
            formHolder.classList.remove('hidden');
            formHolder.style.height = `${(formHolder.clientHeight + 100).toString()}px`;
          } else {
            popout.classList.add('popout-closed');
            popout.classList.remove('popout-open');
            if (!btn.classList.contains('mobile-popout')) {
              popout.classList.add('rotate-90');
              popout.classList.add('md:rotate-270');
              popout.classList.add('lg:rotate-270');
            }
            btn.firstElementChild.classList.add('hidden');
            popout.style.right = popoutOffset;
            formHolder.classList.add('hidden');
            popout.style.height = 'auto';
            formHolder.style.height = 'auto';
            allowForAlertBanner();
          }
        });
      });

      openBtnsMobile.forEach((btn) => {
        btn.addEventListener('click', (e) => {
          e.preventDefault();
          document.body.classList.toggle('overflow-x-hidden');

          if (popout.classList.contains('popout-closed')) {
            popout.classList.remove('popout-closed');
            popout.classList.add('popout-open');
            popout.classList.remove('rotate-90');
            popout.classList.remove('md:rotate-270');
            popout.classList.remove('lg:rotate-270');
            popout.style.right = '0';
            btn.firstElementChild.classList.remove('hidden');
            formHolder.classList.remove('hidden');
            formHolder.style.height = `${(formHolder.clientHeight + 100).toString()}px`;
            popout.style.overflowY = 'scroll';
            formHolder.style.overflowY = 'scroll';
          } else {
            popout.classList.add('popout-closed');
            popout.classList.remove('popout-open');
            if (!btn.classList.contains('mobile-popout')) {
              popout.classList.add('rotate-90');
              popout.classList.add('md:rotate-270');
              popout.classList.add('lg:rotate-270');
            }
            btn.firstElementChild.classList.add('hidden');
            popout.style.right = popoutOffset;
            formHolder.classList.add('hidden');
            popout.style.height = 'auto';
            formHolder.style.height = 'auto';
            allowForAlertBanner();
          }
        });
      });

      if (firstVisibleMessage) {
        if (popout) {
          document.querySelector('#open-popout--desktop').click();
        }
      }
    }
  },
};

export default PopoutModal;
