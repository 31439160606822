const Youtube = {
  init: () => {
    document.querySelectorAll('.video-placeholder').forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        Youtube.loadVideo(element);
      });
    });
  },

  loadVideo: (element) => {
    const videoId = element.getAttribute('data-video-id');
    if (!videoId) return;

    const parent = element.parentNode;
    if (!parent) return;

    const iframe = document.createElement('iframe');
    iframe.setAttribute('title', 'Embedded Video');
    iframe.setAttribute(
      'src',
      `https://www.youtube.com/embed/${videoId}?autoplay=1&fs=0&rel=0&autohide=1&enablejsapi=1`
    );
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'autoplay; fullscreen');
    iframe.setAttribute('allowfullscreen', 'true');
    iframe.classList.add('w-full', 'h-full');
    iframe.style.aspectRatio = '16 / 9';

    if (parent.contains(element)) {
      element.replaceWith(iframe);
    }
  },
};

document.addEventListener('DOMContentLoaded', Youtube.init);

export default Youtube;
